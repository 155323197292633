import React, { useEffect } from "react";
import { useGetLists, deleteList } from "services/lists";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  Grid,
  Typography,
  List as MuiList,
  ListItem,
  ListItemAvatar,
  Avatar,
  useTheme,
  ListItemText,
  Button,
  CircularProgress,
  ListItemButton,
  TableRow,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { useNavigate } from 'react-router-dom'

import { Link } from "react-router-dom";
import Loading from "components/ui/Loading";
import ErrorAlert from "components/ErrorAlert";
import BlockCard from "components/List/BlockCard";
import CustomTable, { CustomTableCell as TableCell } from "components/ui/CustomTable";
import {
  Dvr,
  AccountBox,
  Block,
  Domain,
  VerifiedUser,
  SystemSecurityUpdate,
  DeleteForever,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import { CardWrapper } from "components/ui/CardWrapper";
import EditLeagueForm from "components/forms/EditLeagueForm";
// import { List as ListType } from "types";
import Papa from "papaparse";
import DialogComponent from "components/ui/Dialog";
import FieldSelector, { Uploads } from "components/List/FieldSelector";
import { makeProfileRecords, reverseImportMutation } from "services/lists";
import { useAppContext } from "lib/contextLib";
import axiosService from "lib/axios";
import moment from "moment";
import useList from "hooks/useList";
import { List as ListType } from "store/reducers/list.reducer";

export const fields = [
  "email",
  "first",
  "last",
  "address_1",
  "address_2",
  "city",
  "state",
  "country",
  "zip",
  "phone",
  "gender",
  "dob",
  "ethnicity",
  'extra_1',
  'extra_2',
  'extra_3',
  'extra_4',
  'IGNORE'
];
const chunkSize = 30000 * 1024;

const List = () => {

  const { setSnackbarState } = useAppContext();
  const { listId } = useParams();
  const theme = useTheme();
  const [progress, setProgress] = React.useState(0);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [fieldSelections, setFieldSelections] = React.useState<string[]>(['email', 'first', 'last', 'address_1', 'city', 'IGNORE', 'state', 'country', 'zip', 'IGNORE', 'gender', 'IGNORE', 'IGNORE']);
  const [rowsToShow, setRowsToShow] = React.useState<any[]>([]);
  const [successfulImports, setSuccessfulImports] = React.useState(0);
  const [failedImports, setFailedImports] = React.useState(0);
  const [totalUploadFiles, setTotalUploadFiles] = React.useState(1);
  const [delSecret, setDelSecretKey] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  // const {
  // data, 
  // isError, 
  // isLoading,
  // refetch: refetchLists } = useGetLists();
  const { list: data, isLoading, error: isError, getAllList } = useList();
  const [file, setFile] = React.useState<File>();
  const [fileName, setFileName] = React.useState<string>("");
  const [timeStarted, setTimeStarted] = React.useState<number>(0);
  const [timeElapsed, setTimeElapsed] = React.useState<number>(0);
  const [rawUploadSuccess, setRawUploadSuccess] = React.useState(false);
  const [currentChunkIndex, setCurrentChunkIndex] = React.useState<number | null>(null);
  const [uploadId, setUploadId] = React.useState<string | null>(null);
  const [uploads, setUploads] = React.useState<any[]>([]);
  const [importedCount, setImportedCount] = React.useState(0);
  const [isShowingStats, setIsShowingStats] = React.useState(false);
  const [chunksToUpload, setChunks] = React.useState<any[]>([]);
  const [chunkString, setChunkString] = React.useState<string>("");
  const [parseFileName, setParseFileName] = React.useState<string>("");
  const [parseStart, setParseStart] = React.useState(false);
  const [dataAddProcess, setDataAddProcess] = React.useState(false);
  const [addToProfile, setAddToProfile] = React.useState(false);

  const { mutate: reverseImportMutate, isLoading: isReverseImportLoading } = useMutation(reverseImportMutation, {
    onSuccess: (data) => {
      setFile(undefined);
      setRawUploadSuccess(false);
      setSnackbarState({
        color: "success",
        message: `${data.count} Raw records deleted`,
        open: true,
      });
      setUploadId(null);
      setIsPreviewDialogOpen(false);
      setUploads([]);
      getAllList();
    },
    onError: (err: Error) => {
      setSnackbarState({
        color: "error",
        message: err.message || "Something went wrong",
        open: true,
      })
    },
  });
  const { mutate: makeProfileRecordsMutation, isLoading: isMakeProfilesLoading,
  } = useMutation(({ listId, uploadId }: { listId: string; uploadId?: string }) => makeProfileRecords(listId, uploadId), {
    onSuccess: () => {
      setFile(undefined);
      setRawUploadSuccess(false);
      setSnackbarState({
        open: true,
        message: "Profiles Created Successfully",
        color: "success",
      });
      setUploadId(null);
      setIsPreviewDialogOpen(false);
      setUploads([]);
      getAllList();
    },
    onError: (error: Error) => {
      setSnackbarState({
        open: true,
        message: error.message || "Something went wrong",
        color: "error",
      });
    },
  });

  const [list, setList] = React.useState<ListType | null>(null);

  const maxNum = React.useMemo(() => {
    return Math.max(...rowsToShow.map((item) => item.length));
  }, [rowsToShow]);

  React.useEffect(() => {
    if (listId && data) {
      const listFiltered =
        data.find((list) => list.id === parseInt(listId)) || null;
      setList(listFiltered);
    }
  }, [data, isLoading, listId]);

  React.useEffect(() => {
    if (currentChunkIndex !== null && file) {
      const chunks = Math.ceil(file.size / chunkSize);
      
      setProgress(Math.round((currentChunkIndex / chunks) * 100));
      uploadChunk();
      // update time elapsed 
      setTimeElapsed(Math.round((Date.now() - timeStarted) / 1000));
    }
  }, [currentChunkIndex]);

  React.useEffect(() => {
    // to make sure that previous selections for any fields not present in this file do not persist
    const slicedFieldSelections = fieldSelections.slice(0, maxNum);
    // setFieldSelections(slicedFieldSelections);
    const localStorageItem = {
      selections: slicedFieldSelections,
    }
    window.localStorage.setItem("fieldSelections", JSON.stringify(localStorageItem));
  }, [maxNum]);

  // add data to profiles table
  const approveImport = async () => {
    if (uploadId && listId) {
      // makeProfileRecordsMutation({ listId, uploadId })
      try{
        await axiosService.post(`raw/addToProfile/${listId}?uploadId=${uploadId}`).then((data: any) => {
          setAddToProfile(true);
        })
      }catch(e){
        console.log(e);        
      }
    }
  }

  // restart add to profile if it is stopped
  const startAddProfile = async(offset: number, rows: number) => {
    if (uploadId && listId) {
      try{
        await axiosService.post(`raw/restart/addToProfile/${listId}?uploadId=${uploadId}&startOffset=${offset}&rows=${rows}`).then((data: any) => {
          checkAddToProfile();
        })
      }catch(e){
        console.log(e);        
      }
    }
  }
  
  //check add data to profile status
  useEffect(() => {
    if(addToProfile){
      checkAddToProfile();
    }
  }, [addToProfile])

  //check add data to profile status function
  const checkAddToProfile = () => {
    try{
      axiosService.post(`raw/checkAddToProfile/${listId}`,
        {
          uploadId: uploadId
        }
      ).then((data: any) => {
        if(data.data.status === "adding"){
          setTimeout(() => {
            checkAddToProfile();
          }, 20000);
        }else if(JSON.parse(data.data.status).status === "stopped"){
          const details = JSON.parse(data.data.status);
          startAddProfile(details.offset, details.rows);
        }
        else{
          const response = JSON.parse(data.data.status);
          setTimeout(() => {
            startAddFailedBatchProfile();
          }, 2000);
          // setRawUploadSuccess(false);
          // setSnackbarState({
          //   open: true,
          //   message: "Profiles Created Successfully",
          //   color: "success",
          // });
          // setUploadId(null);
          // setIsPreviewDialogOpen(false);
          // setUploads([]);
          // getAllList();
        }
      })
    }catch(error: any){
      setSnackbarState({
        open: true,
        message: error.message || "Something went wrong",
        color: "error",
      });
    }
  }

  // Check and  start process for failed batches
  const startAddFailedBatchProfile = async () => {
    await axiosService.post(`raw/getFailedBatchData/${listId}`,
      {
        uploadId: uploadId
      }
      ).then((data: any) => {
        const details = data.data.status;
        if(details !== ''){
          axiosService
            .post(`raw/upload/addFailedBatchData/${listId}`,
            {
              uploadId: uploadId,
              listData: details
            },
            {headers:{"Content-Type": "application/json"}})
            .then(({ data }) => {
              checkFailedBatchProcess();
            })
        }else{
          setAddToProfile(false);
          setFile(undefined);
          setRawUploadSuccess(false);
          setSnackbarState({
            open: true,
            message: "Profiles Created Successfully",
            color: "success",
          });
          setUploadId(null);
          setIsPreviewDialogOpen(false);
          setUploads([]);
          getAllList();
        }
      })
  }

  const checkFailedBatchProcess = () => {
    try{
      axiosService.post(`raw/checkFailedBatchData/${listId}`,
        {
          uploadId: uploadId
        }
      ).then((data: any) => {
        if(data.data.status === "adding"){
          setTimeout(() => {
            checkFailedBatchProcess();
          }, 20000);
        }else if(JSON.parse(data.data.status).status === "stopped"){
          const details = JSON.parse(data.data.status);
          restartAddFailedBatchProfile(details.index);
        }
        else{
          const response = JSON.parse(data.data.status);
          setAddToProfile(false);
          setFile(undefined);
          setRawUploadSuccess(false);
          setSnackbarState({
            open: true,
            message: "Profiles Created Successfully",
            color: "success",
          });
          setUploadId(null);
          setIsPreviewDialogOpen(false);
          setUploads([]);
          getAllList();
        }
      })
    }catch(error: any){
      setSnackbarState({
        open: true,
        message: error.message || "Something went wrong",
        color: "error",
      });
    }
  }

  const restartAddFailedBatchProfile = (index: number) => {
    try{
      axiosService.post(`raw/restart/addFailedBatchData/${listId}`,
        {
          uploadId: uploadId,
          index: index
        }
      ).then((data: any) => {
        if(data.data.status === "adding"){
          setTimeout(() => {
            checkFailedBatchProcess();
          }, 20000);
        }else if(JSON.parse(data.data.status).status === "stopped"){
          const details = JSON.parse(data.data.status);
          restartAddFailedBatchProfile(details.index);
        }
        else{
          const response = JSON.parse(data.data.status);
          setAddToProfile(false);
          setFile(undefined);
          setRawUploadSuccess(false);
          setSnackbarState({
            open: true,
            message: "Profiles Created Successfully",
            color: "success",
          });
          setUploadId(null);
          setIsPreviewDialogOpen(false);
          setUploads([]);
          getAllList();
        }
      })
    }catch(error: any){
      setSnackbarState({
        open: true,
        message: error.message || "Something went wrong",
        color: "error",
      });
    }
  }

  const reverseImport = () => {
    if (uploadId && listId) {
      reverseImportMutate({ uploadId, listId })
    }
  }

  function uploadChunk() {
    if (!file || currentChunkIndex === null) return;
    const reader = new FileReader();
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);

    reader.onload = (e) => {
      const result = e.target?.result;
      if (result) {
        const headers = { "Content-Type": "application/octet-stream" };
        const filesize = file.size;
        const chunks = Math.ceil(filesize! / chunkSize) - 1;
        const totalTempFiles = Math.ceil(chunks+1)/23;
        const oneFileChunkSize = Math.ceil(Math.ceil(filesize! / chunkSize)/totalTempFiles);
        
        const isLastChunk = currentChunkIndex === chunks;
        const params = new URLSearchParams();
        params.set('name', fileName);
        params.set('currentChunkIndex', currentChunkIndex.toString());
        params.set('totalChunks', Math.ceil(file.size / chunkSize).toString());
        params.set('uploadId', uploadId?.toString() || '');
        params.set('currentFileChunk', Math.ceil((currentChunkIndex+1)/oneFileChunkSize).toString());

        axiosService
          .post(`/raw/upload/${listId}?${params.toString()}`,
            {
              data: result,
              fields: JSON.stringify(fieldSelections),
            },
            { headers }
          )
          .then(({ data }) => {
            setSuccessfulImports(successfulImports + 1);

            if (currentChunkIndex === 0) {
              setUploadId(data.uploadId);
            }

            if (isLastChunk) {
              setCurrentChunkIndex(null);
              // call parsefile function
              axiosService
              .post(`/raw/upload/parsefile/${listId}`,
                {
                  // fileToCopy: data.filename,
                  uploadId: data.uploadId,
                  fields: JSON.stringify(fieldSelections),
                  totalTempFiles: totalUploadFiles
                },
                {headers:{
                  "Content-Type": "application/json"
                }}
              )
              .then(({ data }) => {
                // setParseFileName(data.filename);
                setParseStart(true);
                setIsUploading(false);
                setProgress(0);
              })
            } else {
              setCurrentChunkIndex(currentChunkIndex! + 1);
            }
          })
          .catch((err) => {
            setFailedImports(failedImports + 1);
            setSnackbarState({
              open: true,
              message: 'Failed to Upload this chunk',
              color: "error",
            })
            if (isLastChunk) {
              setIsUploading(false);
              setIsPreviewDialogOpen(false);
              setFile(undefined);
              setProgress(0);
              return
            }
            setCurrentChunkIndex(currentChunkIndex! + 1);
          });
      }
    };
    reader.readAsDataURL(blob);
  }

  useEffect(() => {
    if(parseStart){
      checkParseStatus();
    }
  }, [parseStart]);

  function checkParseStatus(){
    axiosService
      .post(`/raw/upload/checkparsestatus/${listId}`,
        {
          uploadId: uploadId
        },
        {headers:{"Content-Type": "application/json"}})
        .then(({ data }) => {
          if(data.status === "parsing"){
            setTimeout(() => {
              checkParseStatus();
            }, 20000);
          }else{
            setParseStart(false);
            addDataToRaw();
          }
        });
  }

  function addDataToRaw(){
    axiosService
    .post(`/raw/upload/adddata/${listId}`,
    {
      // fileToCopy: parseFileName,
      totalTempFiles: totalUploadFiles,
      uploadId: uploadId,
      fields: JSON.stringify(fieldSelections)
    },
    {headers:{"Content-Type": "application/json"}})
    .then(({ data }) => {
      setDataAddProcess(true);
    });
  }

  useEffect(() => {
    if(dataAddProcess){
      checkAddDataStatus();
    }
  }, [dataAddProcess])

  function checkAddDataStatus(){
    axiosService
    .post(`/raw/upload/checkadddata/${listId}`,
    {
      uploadId: uploadId
    },
    {headers:{"Content-Type": "application/json"}})
    .then(({ data }) => {
      if(data.status === 'uploading'){
        setTimeout(() => {
          checkAddDataStatus();
        }, 20000);
      }else{
        const response = JSON.parse(data.status);
        setDataAddProcess(false);        
        setUploads(JSON.parse(response.uploads));
        setImportedCount(response.totalCount)
        // setFile(undefined);
        setCurrentChunkIndex(null);
        setRawUploadSuccess(true);
        setSnackbarState({
          open: true,
          message: "List imported to raw successfully",
          color: "success",
        });
      }
    });
  }

  const handleFieldSelect = (index: number, value: string) => {
    const newSelections = [...fieldSelections];
    newSelections[index] = value;
    const localStorageItem = {
      selections: newSelections,
    }
    setFieldSelections(newSelections);
  };

  const onSuccessfulUpdate = () => {
    setIsEditing(false);
    setSnackbarState({
      open: true,
      message: "List updated successfully",
      color: "success",
    });
    getAllList();
  };

  const uploadFile = () => {
    if (!file) {
      return;
    }
    if (
      fieldSelections.length === maxNum &&
      fieldSelections.filter((item) => item).length === maxNum
    ) {
      if (file) {
        setCurrentChunkIndex(null);
        setCurrentChunkIndex(0);
        setIsUploading(true);
        // save current time to calculate time taken for upload
        setTimeStarted(Date.now());
        setIsShowingStats(true);
        setFileName(file.name + Date.now());
        const totalTempFiles = Math.ceil(Math.ceil(file.size / chunkSize)/23);
        // const oneFileChunkSize = Math.ceil(Math.ceil(file.size! / chunkSize)/totalTempFiles);
        setTotalUploadFiles(totalTempFiles);
        // console.log('oneFileChunkSize in one: ', oneFileChunkSize);
      }
    } else {
      setSnackbarState({
        open: true,
        message: "Please select all fields",
        color: "error",
      });
    }
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const rows: any[] = [];
      Papa.parse(file, {
        step(results, parser) {
          rows.push(results.data);
          if (rows.length === 20) {
            parser.abort();
          }
        },
        complete() {
          setRowsToShow(rows);
          setIsPreviewDialogOpen(true);
        },
      });
      setFile(file);
      e.target.value = "";
    }
  };

  const onMakeProfiles = () => {
    if (listId) makeProfileRecordsMutation({ listId });
  };

  const a: any[] = [];
  const b: any[] = [];
  // let num =0;

  React.useEffect(() => {
    console.log(chunkString.length)
  }, [chunkString])

  const reader = new FileReader();
  const obj: any = {};
  const emails = ['brodeur@aol.com']
  // const emails =  `bolanosj@sbcglobal.net
  //   bolayjayona@yahoo.com
  //   bolcdenise@aramark.com
  //   bold89@hotmail.com
  //   boldenderrick@gmail.com
  //   bolden@opportunityinc.com
  //   bolder5@hotmail.com
  //   boldlion@yahoo.com
  //   boldsarmgl@yahoo.com
  //   boldt1783@butlerbremer.com
  //   bolducavt@juno.com
  //   bolducp@yahoo.com
  //   boleary@marinaassociates.com
  //   bolegz519@yahoo.com
  //   bolenek@titanelec.com
  //   bolennobleent@msn.com
  //   boletussatanus@mail.com
  //   boley2@webtv.net
  //   bolgersrlikeonions@juno.com
  //   bolguinsalas@aol.com
  //   bolgy2@juno.com
  //   boli67@twcny.rr.com
  //   boliet@sbcglobal.net
  //   bolinball@earthlink.net
  //   bolindierkes@sbc.org
  //   bolinena@msn.com
  //   boling@coxinternet.com
  //   bolinjohn@hotmail.com
  //   bolink@duocounty.com
  //   bolin.sandra@sulzerrutiusa.com
  //   bolito31@hotmail.com
  //   bolivarez@ntsonline.net
  //   bolivianprincess@hotmail.com
  //   boliviapatyjason@worldnet.att.net
  //   bollabob@iwom.com
  //   boller00@excite.com
  //   bollerg@hotmail.com
  //   bolligermovers@att.net
  //   bollinger@turnerinvest.com
  //   bollyaye@champmail.com
  //   bolo1@msn.com
  //   bolo1@yahoo.com
  //   bolobmg@blackplanet.com
  //   bolobmg@yahoo.com
  //   bolofit@comcast.net
  //   bolofreewayharley@yahoo.com
  //   bolog@hotmail.com
  //   bololethal@prodigy.net
  //   bolonski01@coxinternet.com
  //   bolos1@hotmail.com
  //   bolotin@hicammsd.hitachi.com
  //   bolsarequest@acarpe.com
  //   bolsasn@cool.servicenet.com
  //   bolsasn@cool.servicenet.com.ar
  //   bolsasn@soporte.servicenet.com
  //   bolsasn@soporte.servicenet.com.ar
  //   bolsen@heritagequality.com
  //   bolsen@wcfutah.com
  //   bolsonpasqualino@hotmail.com
  //   bolt9@hotmail.com
  //   boltage12703@wastateresident.com
  //   bolt@archronicle.com
  //   boltfan@msn.com
  //   bolthotty@aol.com
  //   boltman@juno.com
  //   boltman@msn.com
  //   bolton1@hotmail.com
  //   bolton23@hotmail.com
  //   boltonb@journeystravel.com
  //   bolton@coxinternet.com
  //   bolton@erols.com
  //   boltoninn@myself.com
  //   boltonj@hotmail.com
  //   boltonline@cspgroup.com
  //   bolton@pacbell.net
  //   boltsrays@msn.com
  //   boltthrower07@tampabay.rr.com
  //   boltzikladin@peoplepc.com
  //   boluajayi@yahoo.com
  //   bolus@i55.com
  //   bolyard@olcarchitects.com
  //   bomak@mailcity.com
  //   bomalleygoebel@aol.com
  //   bomalleywikstrom@juno.com
  //   boman@deathstar.com
  //   bomara@carolinahandling.com
  //   bomarawalmsley@yahoo.com
  //   bomb23@hotmail.com
  //   bomb81@hotmail.com
  //   bomb966@163.com
  //   bomba101@hotmail.com
  //   bomba123@hotmail.com
  //   bombab1981@hotmail.com
  //   bombardon11997@wastateresident.com
  //   bombasticlove@hotmail.com
  //   bombaybazar@sbcglobal.net
  //   bombayphotos@excite.com
  //   bombayworld@worldnet.att.net
  //   bombb@hotmail.com
  //   bombb@hotmail.com
  //   bombbiggitty@coxinternet.com
  //   bombbomb@yahoo.com
  //   bombchell@webtv.net
  //   bombdiggy@excite.com
  //   bombeirosantos@tivejo.com
  //   bomberger@btcskynet.net
  //   bomberman20082000icall@yahoo.com
  //   bombermom89@coxinternet.com
  //   bombette@yahoo.com
  //   bombfizzle1218@yahoo.com
  //   bombh@hotmail.com
  //   bomb@hotmail.com
  //   bombm@aol.com
  //   bombm@excite.com
  //   bombo@hotmail.co.uk
  //   bombomatic@hotbot.com
  //   bombomdpr@hotmail.com
  //   bombona@hotmail.com
  //   bombonleo90@hotmail.com
  //   bombonlili@hotmail.com
  //   bombonsito@live.com
  //   bombonsitom@hotmail.com
  //   bomboo@thaimail.com
  //   bombshellbetty@hotmail.com
  //   bombsquad@msn.com
  //   bombstemail@msn.com
  //   bomby@hotmail.com
  //   bomeally@renterschoiceinc.com
  //   bomexeg@excite.com
  //   bomindybobaldy@mailblocks.com
  //   bominsky@andrewmoore.net
  //   bommer01@msn.com
  //   bommhe@mcimail.com
  //   bomowezzer@gci.net
  //   bompane@omnistar.com
  //   bom@winnerintl.com
  //   bon1957bon@yahoo.com
  //   bon2@hotmail.com
  //   bon4life@hotmail.com
  //   bon556123@coxinternet.com
  //   bona12@hotmail.com
  //   bonacchi@citynet.com
  //   bonacci5@keynet.net
  //   bonadea@yahoo.com
  //   bonafidelarry@hotmail.com
  //   bonal1@webtv.net
  //   bonamangerrequest@normandycatering.com
  //   bonami@aol.com
  //   bonami@bellsouth.net
  //   bonami@beverlyhanks.com
  //   bonamie3604@coxinternet.com
  //   bonami@netscape.net
  //   bonanox@swbell.net
  //   bonanzababe@hotmail.com
  //   bonaques@fnbcorp.com
  //   bonart@comcast.net
  //   bonart@yahoo.com
  //   bonaz@thebridge.net
  //   bonbee@bright.net
  //   bonbob@msn.com
  //   bonbob@sbcglobal.net
  //   bonbobw@coxinternet.com
  //   bonbom@hotmail.com
  //   bonbon2d@yahoo.com
  //   bonbon420@hotmail.com
  //   bonbon44@hotmail.com
  //   bonbon51@msn.com
  //   bonbon51@yahoo.com
  //   bonbon54@hotmail.com
  //   bonbon58@hotmail.com
  //   bonbon64@hotmail.com
  //   bonbon9@sbcglobal.net
  //   bonbon@adelphia.net
  //   bonbon@aol.com
  //   bonbon@att.net
  //   bonbonbarb@juno.com
  //   bonbon@bellatlantic.net
  //   bonbonbigsaw@msn.com
  //   bonbon@blackplanet.com
  //   bonbonbonnie@people.com
  //   bonbon@bresnan.net
  //   bonboncape@webtv.net
  //   bonbon@centurytel.net
  //   bonbon@charter.net
  //   bonbonc@juno.com
  //   bonbonc@msn.com
  //   bonbon@cybergal.com
  //   bonbond@yahoo.com
  //   bonbon@gurlmail.com
  //   bonbon@hehe.com
  //   bonbon@luukku.com
  //   bonbon@mindspring.com
  //   bonbon@msn.com
  //   bonbon@onebox.com
  //   bonbon@rocketmail.com
  //   bonbons@att.net
  //   bonbons@worldnet.att.net
  //   bonbon@thespa.com
  //   bonbonv@hotmail.com
  //   bonbon@yahoo.com
  //   bonbrad@hotmail.com
  //   boncachic@yahoo.com
  //   boncccm@webtv.net
  //   bon@charteril.com
  //   bonchu@msn.com
  //   bon@coxinternet.com
  //   bond001@worldnet.att.net
  //   bond4204@comcast.net
  //   bond420@yahoo.com
  //   bond70@hotmail.com
  //   bond999@hotmail.com
  //   bondbillbond@buckeyeinet.com
  //   bondbillbond@compuserve.com
  //   bondclegg@attbi.com
  //   bondclegg@mediaone.net
  //   bonddude@peoplepc.com
  //   bonddude@yahoo.com
  //   bondfreimark@msn.com
  //   bondgarbage@mindspring.com
  //   bondgarbage@verizon.net
  //   bond.gary@lutheranbrotherhood.com
  //   bondgear@bondgear.com
  //   bondim@sherwoodoaks.com
  //   bondjames7@hotmail.com
  //   bondjamesbond007@hotmail.com
  //   bondjamesbond@aol.com
  //   bondjamesbond@hotmail.com
  //   bondjamesbond@mailandnews.com
  //   bondjamesbond@msn.com
  //   bondjamesbond@yahoo.com
  //   bondjames@ignmail.com
  //   bondjr@earthling.net
  //   bondjr@earthlink.net
  //   bondjr@hotmail.com
  //   bondjr@yahoo.com
  //   bondkb@comcast.net
  //   bondkiller@hotmail.co.uk
  //   bondl@sbcglobal.net
  //   bondmark@aramark.com
  //   bondmaster@hotmail.com
  //   bondofactor@home.com
  //   bondohoh7@hotmail.com
  //   bondo@juno.com
  //   bondoman@hotmail.com
  //   bondond@chanuteks.com
  //   bondonwalker@hotmail.com
  //   bondonwalker@yahoo.com
  //   bondosan@hotmail.com
  //   bondo@yahoo.com
  //   bondpeter@aramark.com
  //   bondprincess26@yahoo.com
  //   bonds25@mailexcite.com
  //   bondsfive@juno.com
  //   bondstonia@yahoo.com
  //   bond@theonramp.net
  //   bondt@msn.com
  //   bondturner@att.net
  //   bone1@blackplanet.com
  //   bone1@excite.com
  //   bone23@hotmail.com`.split(/\s+/);
  const email = 'bre_nana@msn.com'

  function readChunks(chunks: any[], resultRead = ([] as any[])) {
    if (chunks.length > 0) { // if we still have files left
      const chunk = chunks.shift(); // remove first from queue

      reader.onloadend = function (loadEvent) { // when finished reading file, call recursive readFiles function
        const result = loadEvent.target?.result;
        if (result) {
          // split the result into lines
          const lines = (result as string).split(/\r\n|\n/);

          // const headers = lines.map((line) => {
          //   num ++;
          //   return removeBackspaces(line.split(',')[0]).trim()
          // });
          // console.log(chunksToUpload.length,'chunkstoUpload', headers.length, 'headers');


          //  setChunks([...chunksToUpload, ...headers]);


          // for(let i=0; i<headers.length; i++){
          //   const header = headers[i];
          //   if(header === emails[0] || header.includes(emails[0]) || header.includes('odeur@aol.com')){
          //     console.log('found', header);
          //   }
          //   if(emails.includes(header)){
          //     const emailInLine = lines.filter(line=>{
          //       return removeBackspaces(line.split(',')[0]).trim() === header
          //     })
          //     obj[header]? obj[header].push(emailInLine) : obj[header] = [emailInLine];
          //   }
          // }


          // // index of 'boleary@marinaassociates.com'
          // const email = 'boleary@marinaassociates.com'
          // const index = headers.indexOf(email);
          // if(index !== -1){
          //   console.log('FOUND!', index, headers[index]);
          //   // push that line into the array
          //   b.push(headers[index]);

          // }else {
          //   console.log('NOT FOUND!')
          // }

          // if( Math.abs(num % 500000) <= 100000){
          //    console.log(obj, num);
          // }
          // for(let i = 0; i < lines.length; i++){
          //   const includes = lines[i].includes(email);
          //   if(includes){
          //     console.log('FOUND!');
          //     // push that line into the array
          //     a.push(lines[i]);
          //   }
          // }
          // const duplicates = findDuplicates(headers);
          // console.log(duplicates);

          // get the remaining chunks
          const remainingChunks = chunks.slice(1);
          // call readFiles again with the remaining chunks
          readChunks(remainingChunks);
        }
      }
      reader.readAsText(chunk);

    } else {
      console.log(obj);
      console.log('end') // no more files to read
    }
  }

  function removeBackspaces(str: string) {
    return str.replace("\b", '\'b')
  }
  const handleFileInputTest = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];
    if (file) {

      const chunks = [];

      for (let i = 0; i < file.size; i += chunkSize * 2.5) {
        chunks.push(file.slice(i, i + chunkSize * 2.5));
      }
      readChunks(chunks);

      ev.target.value = "";
    }
  }

  const { mutate: deleteListsMutation, isLoading: isDeleteLoading,
  } = useMutation(({ listId, delSecret }: { listId: string; delSecret: string }) => deleteList(listId, delSecret), {
    onSuccess: () => {
      setFile(undefined);
      setRawUploadSuccess(false);
      setSnackbarState({
        open: true,
        message: "Profiles Deleted Successfully",
        color: "success",
      });
      setIsDeleting(false)
      setOpen(false);
      setDelSecretKey("");
      navigate("/lists");
      getAllList();
    },
    onError: (error: any) => {
      setSnackbarState({
        open: true,
        message: error.response.data.message || "Error deleting List",
        color: "error",
      });
      setOpen(false);
      setIsDeleting(false);
    },
  });
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setIsDeleting(true);
    const listIdData = listId ? listId : ""
    deleteListsMutation({listId: listIdData, delSecret: delSecret});
  }

  const handleClose = () => {
    setOpen(false);
  };

  const profileCount = list?.profiles_count || 0;
  const rawCount = list?.raw_count || 0;

  return (
    <>
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Are You Sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once completed, it cannot be reversed.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="secret"
          name="secret"
          label="Secret Key"
          type="text"
          onChange={(e: any) => setDelSecretKey(e.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleClose()}}>Disagree</Button>
        <Button onClick={handleOk} autoFocus disabled={delSecret === ''}>
          {
            isDeleting ? <CircularProgress size={20} /> : "Agree"
          }
        </Button>
      </DialogActions>
    </Dialog>

    <Grid container direction={"column"}>
      {(isError) && <ErrorAlert />}
      {(isLoading) && <Loading />}
      {list && (
        <Grid item container spacing={2} direction="column">
          <Grid item container justifyContent={"space-between"}>
            <Typography variant="h3"> {list.list_name} </Typography>
            {list.status === "Active" && (
              <Grid item>
                <Button
                  startIcon={<DeleteForever />}
                  component="label"
                  variant="contained"
                  onClick={() => {handleClickOpen()}}
                  color="error"
                  sx={{ marginRight: "1rem" }}
                >
                  {"Delete List"}
                </Button>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<UploadFileIcon />}
                >
                  Import Raw Records
                  <input
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileInput}
                  />
                </Button>
                {file && (
                  <Button
                    sx={{ ml: 1 }}
                    onClick={() => setIsPreviewDialogOpen(true)}
                    variant="contained"
                    color="secondary"
                    component="span"
                  >
                    Open Preview Dialog
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={2}>
            <Grid item sm={12} lg={6}>
              <EditLeagueForm
                list={list}
                onSuccessfulUpdate={onSuccessfulUpdate}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          </Grid>
          <Grid item container>
            <Typography variant="h4">Stats</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6} md={4}>
              <CardWrapper
                sx={{ color: "white", backgroundColor: "info.main" }}
              >
                <MuiList sx={{ py: 0 }}>
                  <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                    <ListItemButton component={Link} to={`/tables/raw?search=${listId}&field=list_id`} >
                      <ListItemAvatar>
                        <Avatar
                          variant="rounded"
                          sx={{
                            backgroundColor: theme.palette.info.dark,
                            color: "#fff",
                          }}
                        >
                          <Dvr fontSize="inherit" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          py: 0,
                          mt: 0.45,
                          mb: 0.45,
                        }}
                        primary={
                          <Typography variant="h3" sx={{ color: "#fff" }}>
                            {new Intl.NumberFormat().format(rawCount)}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="subtitle2" sx={{ mt: 0.25 }}>
                            Raw Records from list
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </MuiList>
              </CardWrapper>
            </Grid>
            <Grid item xs={6} md={4}>
              <CardWrapper sx={{ color: "white" }}>
                <MuiList sx={{ py: 0 }}>
                  <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                    <ListItemButton component={Link} to={`/profiles?search=${listId}&field=list_id`} >
                      <ListItemAvatar>
                        <Avatar
                          variant="rounded"
                          sx={{
                            backgroundColor: theme.palette.primary.dark,
                            color: "#fff",
                          }}
                        >
                          <AccountBox fontSize="inherit" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          py: 0,
                          mt: 0.45,
                          mb: 0.45,
                        }}
                        primary={
                          <Typography variant="h3" sx={{ color: "#fff" }}>
                            {new Intl.NumberFormat().format(profileCount)}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "secondary.light", mt: 0.25 }}
                          >
                            Profiles from this List
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </MuiList>
              </CardWrapper>
            </Grid>

            <Grid item md={4} sm={12}>
              <Grid container alignItems="center" spacing={0}>

                <BlockCard
                  url={`/profiles?list_id=${listId}&block_reason=blocked_domains`}
                  rawLength={rawCount}
                  title="Blocked Domains"
                  value={list.stats_dblock}
                  icon={<Domain />}
                />
                <BlockCard
                  url={`/profiles?list_id=${listId}&block_reason=blocked_roles`}
                  rawLength={rawCount}
                  title="Blocked Role's"
                  value={list.stats_rblock}
                  icon={<VerifiedUser />}
                />
              </Grid>
              <Grid container alignItems="center" spacing={0}>
                <BlockCard
                  url={`/profiles?list_id=${listId}&block_reason=allowed_tld`}
                  rawLength={rawCount}
                  title="Blocked TLD's"
                  value={list.stats_tldblock}
                  icon={<SystemSecurityUpdate />}
                />
                <BlockCard
                  url={`/profiles?list_id=${listId}&block_reason=blacklist`}
                  rawLength={rawCount}
                  title="Black List"
                  value={list.stats_blacklist}
                  icon={<Block />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {file && maxNum > 0 && (
        <DialogComponent
          dialogProps={{
            open: isPreviewDialogOpen,
            fullScreen: true
          }}
          isDialogOpen={isPreviewDialogOpen}
          closeDialog={() => setIsPreviewDialogOpen(false)}
          title={rawUploadSuccess ? `${importedCount} records inserted from ${file.name}` : file.name + " Preview"}
          dialogActions={
            <Grid container justifyContent={"flex-end"} spacing={2} p={2}>
              {!isUploading && (
                <>
                  <Grid item>
                    {!rawUploadSuccess ? <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      disabled={parseStart || dataAddProcess || addToProfile}
                      onClick={() => setIsPreviewDialogOpen(false)}
                    >
                      Cancel
                    </Button> : 
                    <Button
                      variant="outlined"
                      color="error"
                      component="span"
                      disabled={isReverseImportLoading || isMakeProfilesLoading}
                      onClick={reverseImport}
                    >
                      {isReverseImportLoading ? <CircularProgress /> : 'Reverse Import'}
                    </Button>
                    }
                  </Grid>
                  <Grid item>
                    {!rawUploadSuccess ? <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={uploadFile}
                      disabled={parseStart || dataAddProcess || addToProfile}
                    >
                      Upload
                    </Button> : 
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{color: "#FFFFFF"}}
                      // disabled={isReverseImportLoading || isMakeProfilesLoading}
                      component="span"
                      onClick={approveImport}
                    >
                      {addToProfile ? <> <Typography variant="body1" sx={{marginRight: "10px"}}>Uploading</Typography> <CircularProgress sx={{color: "#FFFFFF"}} size={24} /></> : 'Approve Import'}
                      {/* {isMakeProfilesLoading ? <CircularProgress /> : 'Approve Import'} */}
                    </Button>}
                  </Grid>
                </>
              )}
            </Grid>
          }
        >
          <Grid container direction={"column"} justifyContent="center">
            {!uploadId && <Grid item pt={1} pb={1}>
              {" "}
              <Typography>
                Number of fields/row for this file = {maxNum}{" "}
              </Typography>{" "}
            </Grid>}
            {isShowingStats && (
              <Grid item container justifyContent="center" alignItems="center" pb={4}>
                <Grid item py={2} mt={uploads ? 2 : 8} xs={12} >
                  <CustomTable
                    tableHead={
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell>Successful Imports</TableCell>
                        <TableCell>Failed Imports</TableCell>
                        <TableCell>Time Started</TableCell>
                        <TableCell>Time Elapsed</TableCell>
                      </TableRow>
                    }
                  >
                    <TableRow>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>{successfulImports}</TableCell>
                      <TableCell>{failedImports}</TableCell>
                      <TableCell>{moment(timeStarted).format('MM/DD/YYYY hh:mm a')}  </TableCell>
                      <TableCell>{moment.utc(timeElapsed * 1000).format('HH:mm:ss')}</TableCell>
                    </TableRow>
                  </CustomTable>
                </Grid>
                <Button variant="contained" color="info" onClick={() => setIsShowingStats(false)} disabled={parseStart || dataAddProcess || addToProfile}>Clear Stats </Button>
              </Grid>
            )}
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            {
              parseStart ? 
              <>
                <CircularProgress />
                <p>Parsing...</p>
              </>
              : ""
            }
            {
              dataAddProcess ? 
              <>
                <CircularProgress />
                <p>Data Uploading...</p>
              </>
              : ""
            }
            </Box>
            {!isUploading ? (
              <>
                {!uploadId ? <FieldSelector maxNum={maxNum}
                  handleFieldSelect={handleFieldSelect}
                  fieldSelections={fieldSelections}
                  first10Rows={rowsToShow} /> : uploads ? <Uploads uploads={uploads} /> : 'Upload Completed'}
              </>
            ) : (
              <Grid container direction="column" justifyContent={'space-between'} spacing={5}>
                <Grid item>
                  <div className="file">
                    <div className="name">Progress</div>
                    <div
                      className={"progress " + (progress === 100 ? "done" : "")}
                      style={{ width: progress + "%" }}
                    >
                      {progress}%
                    </div>
                  </div>
                </Grid>

              </Grid>
            )}
          </Grid>


        </DialogComponent>
      )}
    </Grid>
    </>
  );
};

// function Uploads({ uploads }:{uploads:any[]}) {
//   return (

//   )
// }


export default List;
