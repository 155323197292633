import React from "react";
import { deleteJobs, deleteSingleJob, getJobs, Job } from "services/jobs";
import { useMutation } from "@tanstack/react-query";
import useQuery from "lib/useQuery";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import {
  Grid,
  TableRow,
  TableCell,
  Chip,
  Button,
  DialogContentText,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box
} from "@mui/material";
import ErrorAlert from "components/ErrorAlert";
import DialogComponent from "components/ui/Dialog";
import { daysOfWeek, rowsPerFetch } from "../constants";
import CreateNewJob from "components/forms/CreateNewJob";
import { useAppContext } from "lib/contextLib";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useJobs from "hooks/useJobs";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const jobsColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "daily_lead_cap",
    numeric: true,
    disablePadding: false,
    label: "Daily Cap",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "recurring",
    numeric: false,
    disablePadding: false,
    label: "Recurring",
  },
  {
    id: "mv_rejects",
    numeric: false,
    disablePadding: false,
    label: "MV Rejects",
  },
  {
    id: "oe_rejects",
    numeric: false,
    disablePadding: false,
    label: "EO Rejects",
  },
  {
    id: "job_details_count",
    numeric: false,
    disablePadding: false,
    label: "MW Valid",
  },
  {
    id: "days_running",
    numeric: false,
    disablePadding: false,
    label: "Days Running",
  },
  {
    id: "date_completed",
    numeric: true,
    align: "right",
    disablePadding: false,
    label: "Date Completed",
  },
  {
    id: "actions",
    end: true,
    disablePadding: false,
    align: "right",
    label: "Actions",
  },
];

const Jobs = () => {
  const { jobs, getAllJobs } = useJobs();
  const { setSnackbarState } = useAppContext();

  const { isLoading: isDeleteJobLoading, mutate } = useMutation(deleteJobs, {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "Job deleted successfully",
        color: "success",
      });
      handleSearch();
    },
    onError: () => {
      setSnackbarState({
        open: true,
        message: "Error deleting job",
        color: "error",
      });
    },
  });

  const query = useQuery();
  const [activePage, setActivePage] = React.useState(0);
  const [jobsToDel, setJobsToDel] = React.useState<any[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [deleteInputVal, setDeleteInputVal] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [delSecret, setDelSecretKey] = React.useState("");
  const [deleteId, setDeleteId] = React.useState("");
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { isLoading: isDeleteSingleJobLoading, mutate: deleteSingleJobMutate } = 
  useMutation(({ deleteId, delSecret }: { deleteId: string; delSecret: string }) => deleteSingleJob(deleteId, delSecret), {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "Job deleted successfully",
        color: "success",
      });
      setIsDeleting(false)
      handleSearch();
      setOpen(false)
      setDelSecretKey("");
      setDeleteId("");
    },
    onError: (error: any) => {
      setSnackbarState({
        open: true,
        message: error.response.data.message || "Error deleting List",
        color: "error",
      });
      setDelSecretKey("");
      setIsDeleting(false)
    },
  });
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setIsDeleting(true)
    deleteSingleJobMutate({deleteId, delSecret});
  }

  const handleClose = () => {
    setOpen(false);
  };

  const deleteSingleJobAction = (e: any, id: string ) => {
    e.stopPropagation();
    setDeleteId(id);
    setOpen(true);
  };

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  const incrementPage = (value: number) => {
    setActivePage(value);
  };
  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (jobs?.lodedPageData as any) && jobs?.jobs?.length !== jobs?.total) ||
      (!jobs?.jobs?.length || jobs?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const handleSearch = ({ ...query } = {}) => {
    getAllJobs({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  }

  const [createJobDialogOpen, setCreateJobDialogOpen] = React.useState(false);
  const onJobCreateClick = () => {
    setCreateJobDialogOpen(true);
  };
  const onJobsDelete = (ids: string[]) => {
    setJobsToDel(ids);
    setIsDeleteDialogOpen(true);
  };
  const handleSuccess = () => {
    setCreateJobDialogOpen(false);
    setSnackbarState({
      open: true,
      message: "Job created successfully",
      color: "success",
    });
    handleSearch();
  };
  const handleJobsDeleteConfirm = () => {
    mutate(jobsToDel);
    setIsDeleteDialogOpen(false);
  };

  return (
    <Grid container>
      {jobs?.error && <ErrorAlert />}
      <EnhancedCustomTable
        onAddClick={onJobCreateClick}
        onDeleteClick={onJobsDelete}
        onSingleJobDelete={deleteSingleJobAction}
        Row={JobRow}
        headCells={jobsColumns}
        rows={jobs?.jobs ?? []}
        incrementFetch={incrementPage}
        totalCountParent={jobs?.total}
        pageParent={activePage}
        fieldsToSearch={["status", "Daily_Lead_Cap", "Recurring"]}
        isLoading={(jobs?.isLoading || isDeleteJobLoading) ?? false}
        selectionKey="id"
        title="Jobs"
      />

    {/* Single Delete Job Popup */}

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
      >
      <DialogTitle id="alert-dialog-title">
        {"Are You Sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once completed, it cannot be reversed.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="secret"
          name="secret"
          label="Secret Key"
          type="text"
          onChange={(e: any) => setDelSecretKey(e.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleClose()}}>Disagree</Button>
        <Button onClick={handleOk} autoFocus disabled={delSecret === ''}>
          {
            isDeleting ? <CircularProgress size={20} /> : "Agree"
          }
        </Button>
      </DialogActions>
      </Dialog>

    {/* Single Delete Job Popup */}

      <DialogComponent
        isDialogOpen={isDeleteDialogOpen}
        closeDialog={() => setIsDeleteDialogOpen(false)}
        title="Are you sure you want to delete the selected jobs?"
      >
        <DialogContentText>
          Please type the word &quot;delete&quot; to confirm
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Type delete"
          type="text"
          fullWidth
          value={deleteInputVal}
          onChange={(e) => setDeleteInputVal(e.target.value)}
          variant="standard"
        />

        <DialogActions sx={{ marginTop: 2 }}>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleJobsDeleteConfirm}
            color="error"
            variant="contained"
            disabled={
              isDeleteJobLoading || deleteInputVal.toLowerCase() !== "delete"
            }
          >
            Delete
          </Button>
        </DialogActions>
      </DialogComponent>

      <DialogComponent
        dialogProps={{
          open: createJobDialogOpen,
          fullScreen: true,
        }}
        isDialogOpen={createJobDialogOpen}
        closeDialog={() => setCreateJobDialogOpen(false)}
        title="Create Job"
      >
        <CreateNewJob onSuccess={handleSuccess} />
      </DialogComponent>
    </Grid>
  );
};

function JobRow({
  selectionKey,
  isItemSelected,
  labelId,
  handleClick,
  row,
  onSingleJobDelete
}: RowProps) {

  const navigate = useNavigate();

  const handleViewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/jobs/${row.id}`);
  };

  const total =
    parseInt(row.mv_rejects) +
    parseInt(row.oe_rejects) +
    parseInt(row.job_details_count);
  
  return (
    <>
    <TableRow
      hover
      onClick={(event) => handleClick(event, (row as any)[selectionKey])}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell align="left"> {row.id} </TableCell>
      <TableCell align="left"> {row.name} </TableCell>
      <TableCell align="left">
        {new Intl.NumberFormat().format(row.daily_lead_cap)}
      </TableCell>
      <TableCell align="left">{row.status}</TableCell>
      <TableCell align="left">
        {row.recurring ? (
          <Chip
            sx={{ color: "white" }}
            size="small"
            label="Yes"
            color="success"
          />
        ) : (
          <Chip
            sx={{ color: "white" }}
            size="small"
            label="No"
            color="error"
          />
        )}
      </TableCell>
      <TableCell align="left">
        {new Intl.NumberFormat().format(parseInt(row.mv_rejects))}(
        {new Intl.NumberFormat().format(
          (parseInt(row.mv_rejects) / total) * 100 || 0
        )}
        %)
      </TableCell>
      <TableCell align="left">
        {new Intl.NumberFormat().format(parseInt(row.oe_rejects))}(
        {new Intl.NumberFormat().format(
          (parseInt(row.oe_rejects) / total) * 100 || 0
        )}
        %)
      </TableCell>
      <TableCell align="left">
        {new Intl.NumberFormat().format(parseInt(row.job_details_count))}(
        {new Intl.NumberFormat().format(
          (parseInt(row.job_details_count) / total) * 100 || 0
        )}
        %)
      </TableCell>
      <TableCell align="left">
        <Box sx={{display: "flex", gap: "5px", justifyContent: "start", alignItems: "center" }}>
          {daysOfWeek
            .filter((day) => row[day])
            .map((day) => (
              <Box key={day}>
                <Chip
                  sx={{textTransform: "capitalize"}}
                  size="small"
                  label={day.substring(0, 2)}
                  color="primary"
                />
              </Box>
            ))}
        </Box>
      </TableCell>
      <TableCell align="right">
        {row.date_completed
          ? moment(row.date_completed).format("MM/DD/YY,hh:mm")
          : ""}
      </TableCell>
      <TableCell align="right" sx={{ p: 1, display: "flex", gap: "5px" }}>
        <Button onClick={(e: any) => {onSingleJobDelete(e, row.id)}} variant="contained" color="error">
          Delete
        </Button>
        <Button onClick={handleViewClick} variant="contained">
          View
        </Button>
      </TableCell>
    </TableRow>
    </>
  );
}

export default Jobs;
function setSnackbarState(arg0: { open: boolean; message: string; color: string; }) {
  throw new Error("Function not implemented.");
}

